@tailwind base;
@tailwind components;
@tailwind utilities;

@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

.Roboto {
  font-family: "Roboto", sans-serif;
}
.Poppins {
  font-family: "Poppins", sans-serif;
}
.DM-Sans {
  font-family: "DM Sans", sans-serif;
}
.Inter {
  font-family: "Inter", sans-serif;
}
:root {
  --navbar-bg-color: #252d49;
  --blue-global-color: #748AF2;
  --red-global-color: #f6332a;
  --white-global-color: #ffffff;
  --grey-global-color: #eff0f6;
  --dark-grey-global-color: #6c727f;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.background-navbar-color {
  background-color: var(--navbar-bg-color);
}

.active {
  background-color: #f3f4f6;
  color: var(--blue-global-color);
}
.navbar-font {
  font-size: 15px;
  font-family: "Inter", sans-serif;
  font-weight: 500;
}
.navbar-text:hover {
  text-shadow: 0px 4px 10px rgba(0, 0, 0, 0.25);
}
.icon-upload {
  text-align: -webkit-center;
}

.sidebar-height {
  height: calc(100vh - 188px);
}
.sidebar-height-summary {
  height: calc(100vh - 226px);
}

.submenu-transition-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.submenu-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

.menu-transition-enter {
  opacity: 0;
  transform: translateY(-10px);
}

.menu-transition-enter-active {
  opacity: 1;
  transform: translateY(0);
  transition: opacity 200ms ease-in, transform 200ms ease-in;
}

.menu-transition-exit {
  opacity: 1;
  transform: translateY(0);
}

.menu-transition-exit-active {
  opacity: 0;
  transform: translateY(-10px);
  transition: opacity 200ms ease-out, transform 200ms ease-out;
}

.chevron {
  transition: transform 0.2s ease-in-out;
}

.chevron.rotate {
  transform: rotate(-90deg);
}

.comments_border {
  border-left-width: 8px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  padding-right: 12px;
}

input.error::placeholder {
  color: red;
  opacity: 0.5;
}

div#doc-text p {
  margin-bottom: 16px;
  font-size: 16px;
}
div#doc-text h1 {
  margin-bottom: 16px;
  font-size: 25px;
  font-weight: bold;
}
div#doc-text h2 {
  margin-bottom: 16px;
  font-size: 22px;
  font-weight: medium;
}
div#doc-text h3 {
  margin-bottom: 16px;
  font-size: 20px;
  font-weight: medium;
}
div#doc-text h4 {
  margin-bottom: 16px;
  font-size: 18px;
  font-weight: medium;
}
.quote {
  animation: animateMounting 0.2s;
}

@keyframes animateMounting {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}


.custom_fc_frame {right: 50px !important;bottom: 30px !important;}


.masked-overflow {
  /* scroll bar width, for use in mask calculations */
  --scrollbar-width: 0px;

  /* mask fade distance, for use in mask calculations */
  --mask-height: 70px;

  /* If content exceeds height of container, overflow! */
  overflow-y: hidden;

  /* Need to make sure container has bottom space,
otherwise content at the bottom is always faded out */

  /* Keep some space between content and scrollbar */

  /* The CSS mask */

  /* The content mask is a linear gradient from top to bottom */
  --mask-image-content: linear-gradient(
      to bottom,
      transparent,
      black var(--mask-height),
      black calc(100% - var(--mask-height)),
      transparent
  );

  /* Here we scale the content gradient to the width of the container 
minus the scrollbar width. The height is the full container height */
  --mask-size-content: calc(100% - var(--scrollbar-width)) 100%;

  /* The scrollbar mask is a black pixel */
  --mask-image-scrollbar: linear-gradient(black, black);

  /* The width of our black pixel is the width of the scrollbar.
The height is the full container height */
  --mask-size-scrollbar: var(--scrollbar-width) 100%;

  /* Apply the mask image and mask size variables */
  mask-image: var(--mask-image-content), var(--mask-image-scrollbar);
  mask-size: var(--mask-size-content), var(--mask-size-scrollbar);

  /* Position the content gradient in the top left, and the 
scroll gradient in the top right */
  mask-position: 0 0, 100% 0;

  /* We don't repeat our mask images */
  mask-repeat: no-repeat, no-repeat;
}